<div class="app-user-relates" [formGroup]="userRelatesForm">
  <mat-form-field
    class="w-full prefixed-icon-form short-text"
    appearance="outline"
    subscriptSizing="dynamic"
    (click)="getStationUsersRoster()"
  >
    <mat-label>Field Name</mat-label>
    @if (questionSelected || memberSelected) {
      <div matPrefix class="icon" style="max-width: 30px">
        @if (questionSelected && !memberSelected) {
          @if (
            questionSelected &&
            questionSelected.questionType === fieldTypeEnum.UserSelect
          ) {
            <i class="fak fa-user-select"></i>
          }
          @if (
            questionSelected &&
            questionSelected.questionType === fieldTypeEnum.CreatedBy
          ) {
            <i class="fa-light fa-user-pen"></i>
          }
        }
        @if (!questionSelected && memberSelected) {
          @if (!memberSelected.profileImageRithmId) {
            <i class="text-secondary-300 fa-light fa-user"></i>
          } @else {
            <ng-template
              *ngTemplateOutlet="avatarImage; context: { data: memberSelected }"
            />
          }
        }
      </div>
    }
    <mat-select
      formControlName="selectedValue"
      (selectionChange)="updateFieldAnswer($event.value)"
    >
      <div class="possibleAnswers">
        <mat-option [value]="">--</mat-option>
        @if (getCurrentStationQuestions.length) {
          @for (possibleAnswer of getCurrentStationQuestions; track $index) {
            <mat-option [value]="possibleAnswer">
              <div class="flex items-center space-x-3 pl-1">
                <i
                  [ngClass]="{
                    'fak fa-user-select':
                      possibleAnswer.questionType === fieldTypeEnum.UserSelect,
                    'fa-light fa-user-pen':
                      possibleAnswer.questionType === fieldTypeEnum.CreatedBy
                  }"
                ></i>
                <span> {{ possibleAnswer.prompt }} </span>
              </div>
            </mat-option>
          }
        }
        @if (stationRosterMember.length || isLoadingRosterMembers) {
          @for (member of stationRosterMember; track $index) {
            <mat-option [value]="member" class="text-sm">
              <div class="flex items-center space-x-3">
                @if (!member.profileImageRithmId) {
                  <i class="text-secondary-300 fa-light fa-user pl-1"></i>
                } @else {
                  <ng-template
                    *ngTemplateOutlet="avatarImage; context: { data: member }"
                  />
                }
                <span [ngClass]="{ 'pl-1': !member.profileImageRithmId }">
                  {{ member.firstName + ' ' + member.lastName }}
                </span>
              </div>
            </mat-option>
          }
          @if (isLoadingRosterMembers || !stationRosterMember.length) {
            <mat-option class="pointer-events-none center-loading">
              <app-loading-indicator
                id="loading-account-setting"
                data-testid="loading-account-setting"
                [inlineText]="'Loading..'"
              />
            </mat-option>
          }
        }
      </div>
    </mat-select>
  </mat-form-field>
</div>

<!-- Avatar profile image field-->
<ng-template #avatarImage let-data="data">
  <app-user-avatar
    [firstName]="data.firstName || ''"
    [lastName]="data.lastName || ''"
    [size]="'20'"
    [profileImageRithmId]="data.profileImageRithmId || ''"
  />
</ng-template>
